import React from "react"
import StructurePageTerminos from "@components/pageLegal/templatePageTerminos"
import { Helmet } from "react-helmet"
import data from "@components/pageLegal/data/dataPageTerminosTicker"

const PoliticasPage = ({ location }) => (
  <div>
     <Helmet htmlAttributes={{ lang: 'es' }}>
      <title>Terminos y condiciones Beex Conversations [Antes Securitec]</title>
      <link
        rel="icon"
        type="image/png"
        href={`https://beexcc.com/Beex.png`}
      />
      <link
        rel="canonical"
        href={`https://beexcc.com/terminos-condiciones-ticker/`}
      />
      <meta name="robots" content="noindex, follow"></meta>
      <meta charSet="utf-8" />
      <meta name="description" content="Terminos y condiciones Beex Conversations." />
      <meta property="og:locale" content="es_ES" />
      <meta property="og:type" content="website" />
      <meta property="og:title" content="Terminos y condiciones Beex Conversations" />
      <meta
        property="og:url"
        content={`https://beexcc.com/terminos-condiciones-ticker/`}
      />
      <meta
        property="og:image"
        content={`https://beexcc.com/og-ticker.png`}
      />
      <meta
        property="og:description"
        content="Terminos y condiciones Beex Conversations."
      />
    </Helmet>
    <StructurePageTerminos location={location} data={data}/>
  </div>
)

export default PoliticasPage
