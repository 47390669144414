import React from "react"
import { Link } from "gatsby"
const text = (
  <span>
    Los presentes Términos y Condiciones (los{" "}
    <strong>“Términos y Condiciones”</strong>) representan un contrato entre
    usted (en adelante, el “Cliente”) y Securitec Peru S.A.C (en adelante,
    <strong>“Beex”</strong>). El presente Contrato regula las condiciones
    de uso y acceso al software como servicio (Saas) de atención al cliente
    omnicanal y chatbots (los <strong>“Servicios”</strong>) ofrecido por
    Beex y puestos a su disposición mediante la página web:
    <Link href="https://beexcc.com/conversations/">
      {" "}
      https://beexcc.com/conversations/{" "}
    </Link>{" "}
    (en adelante, la <strong>“Plataforma”</strong>).
    <br /> <br />
    <strong id="Empresa"> 1. Empresa </strong>
    <br /> <br />
    Beex es una sociedad debidamente registrada en el registro de personas
    jurídicas de Lima y Callao, identificada con RUC N° 20600256301, con
    domicilio fiscal en Jr. Pachacutec Nº 1315, Int. 503, Urb. Santa Beatriz,
    distrito de Jesús María, provincia y departamento de Lima.
    <br /> <br />
    <strong id="Objeto-del-Contrato"> 2. Objeto del Contrato </strong>
    <br /> <br />
    Este contrato tiene como objeto que el Cliente se informe de los Términos y
    Condiciones de Uso para el acceso a la Plataforma (los{" "}
    <strong>“Términos y Condiciones”</strong>), así como de cualquier
    modificación que se encuentre vigente al momento en que el Cliente utilice
    la Plataforma. En base a ello, le pedimos que lea cuidadosamente este
    contrato y se asegure que lo entiende completamente antes de acceder a la
    Plataforma o de utilizar los Servicios. El simple acceso a la Plataforma o
    el uso de los Servicios que Beex pone a su disposición, se entenderá
    como la aceptación plena y sin reserva de los mismos y, por ende, su
    compromiso para respetar los Términos y Condiciones. Le recomendamos a su
    vez, que guarde una copia del presente contrato, aunque los mismos estarán
    disponibles en la Plataforma.
    <br /> <br />
    En el caso de que no se encuentre de acuerdo con este contrato, deberá
    abstenerte de utilizar la Plataforma y los Servicios ofrecidos por
    Beex.
    <br /> <br />
    <strong id="Servicio"> 3. Servicio </strong>
    <br /> <br />
    Los Servicios ofrecidos por Beex tienen como principal objetivo poner a
    disposición del Cliente una plataforma omnicanal que le permita la
    comunicación con sus clientes, mediante canales como WhatsApp y Facebook
    Messenger, a través de la automatización de los flujos de atención al
    cliente con chatbots y/o a través de la canalización de los casos más
    complejos con sus trabajadores o locadores (los <strong>“Agentes”</strong>).
    <br /> <br />
    <strong>3.1. Planes de Suscripción</strong>
    <br />
    <br />
    3.1.1 El Cliente podrá acceder a distintos planes de suscripción, de acuerdo
    con lo que más le beneficie:
    <br />
    * Plan Advanced
    <br />
    * Plan Business
    <br />
    * Plan Enterpise
    <br /> <br />
    Las condiciones de los señalados planes y sus precios serán comunicados en
    la Plataforma y/o a través de un asesor de ventas.
    <br /> <br />
    3.1.2 Los planes ofrecidos por Beex comprenden:
    <br />
    * Un costo fijo mensual que depende de la cantidad de “usuarios” contratados
    para la canalización de la atención al cliente a través de Agentes.
    <br />
    * Un costo variable, relacionado a la contratación a través del Market de la
    Plataforma de:
    <br />
    (A) Bolsa de envío de SMS, que permitirá al Cliente recibir notificaciones
    de actividad de su cuenta y/o la de sus Agentes;
    <br />
    (B)Bolsa de envío de plantillas de Whatsapp, que permitirán al Cliente y/o
    sus Agentes comunicarse a través de mensajes predeterminados -según lo
    autorizado por Whatsapp Inc.-; y,
    <br />
    (C) Del Servicio WhatsApp Business API, que puede ser adquirido por el
    Cliente a Beex o a un proveedor de soluciones integrales de WhatsApp
    (BSP), de acuerdo a lo señalado en la cláusula 7.
    <br /> <br />
    3.1.3. Beex se reserva el derecho de cambiar los precios de los planes
    de suscripción o cargos por las funcionalidades ofrecidas en la Plataforma;
    así como iniciar a cobrar, previo aviso, por los servicios gratuitos
    ofrecidos por Beex. Sin embargo, dichos cambios no afectarán el plan
    contratado y la tarifa pagada antes de las modificaciones antedichas, hasta
    su respectiva fecha de vencimiento. Cualquier modificación en los precios de
    los planes de suscripción serán notificados al correo electrónico registrado
    por el Cliente y/o a través de la Plataforma.
    <br /> <br />
    3.1.4. El cobro por el acceso y uso de los Servicios de Beex se
    producirá a través del cargo a una tarjeta de crédito o débito ingresada por
    el Cliente, de acuerdo a las condiciones de las pasarelas de pago puestas a
    disposición por Beex. El Cliente afirma y garantiza ser el titular del
    medio de pago utilizado para el acceso a los Servicios.
    <br /> <br />
    3.1.5. La suscripción contratada será renovada automáticamente, de modo que
    el cobro del plan de suscripción contratado por el Cliente se realizará de
    manera anticipada, en la misma fecha en la que se dio inicio a la
    suscripción de los Servicios.
    <br /> <br />
    3.1.6 Beex podrá ofrecer al Cliente el acceso a los Servicios y a
    determinadas funcionalidades de los mismos de manera gratuita, cuyo tiempo
    de disponibilidad y condiciones de acceso serán determinados y comunicados
    previamente por Beex al correo electrónico registrado por el Cliente
    y/o a través de la Plataforma.
    <br /> <br />
    3.1.7 Beex podrá desarrollar nuevas funcionalidades o facilidades en la
    Plataforma o podrá desarrollar nuevos productos, los cuales serán
    comunicados al Cliente, según corresponda. Estas podrán no estar incluidas
    dentro del plan adquirido inicialmente por el Cliente, por lo que su uso o
    adquisición podrán tener un costo adicional. Estas nuevas funcionalidades se
    añadirán si, y sólo si, el Cliente desea tener acceso a las mismas.
    <br /> <br />
    <strong> 3.2. Cancelación y/o cambios de planes de suscripción </strong>
    <br /> <br />
    3.2.1. <strong> Cancelación: </strong>Los planes de suscripción ofrecidos
    por Beex se renuevan automáticamente, por lo que si el Cliente desea
    cancelar la suscripción de su plan, a fin de que este no sea renovado
    automáticamente, hasta cinco (05) días calendario anteriores a la fecha de
    renovación de su plan, el Cliente podrá: (i) acceder al panel de
    configuración de su cuenta desde la Plataforma, en la cual habrá una opción
    para cancelar la autorrenovación de los Servicios; o (ii) escribir al
    siguiente correo electrónico: support@beexcc.com.
    <br /> <br />
    La decisión de cancelación de su plan en una fecha posterior a la señalada
    en el párrafo anterior, no afectará el periodo ya renovado, de modo que el
    Cliente tendrá acceso a los Servicios hasta el término del período del plan
    renovado. El Cliente reconoce y acepta que no existirá derecho a devolución
    de dinero alguno a favor del Cliente respecto del plan contratado o cuando
    este se hubiera renovado automáticamente, de acuerdo a lo señalado
    anteriormente.
    <br /> <br />
    3.2.2. <strong>Cambios de plan de suscripción:</strong> El Cliente podrá
    cambiar su plan de suscripción si lo considera conveniente, de acuerdo a lo
    señalado a continuación:
    <br /> <br />
    (A) En caso quisiera realizar el <strong>downgrade</strong> de su plan,
    hasta cinco (05) días calendario anteriores a la fecha de renovación de su
    plan, el Cliente podrá: (i) acceder al panel de configuración de su cuenta
    desde la Plataforma, en la cual habrá una opción para realizar el downgrade;
    o (ii) escribir al siguiente correo electrónico: support@beexcc.com. El
    downgrade del plan regirá desde la fecha de renovación de la suscripción;
    asimismo, en dicho momento se realizará el cobro del mismo. (B) En caso
    quisiera realizar el <strong>upgrade</strong> de su plan, el Cliente podrá:
    (i) acceder al panel de configuración de su cuenta desde la Plataforma, en
    la cual habrá una opción para realizar el upgrade; o (ii) escribir al
    siguiente correo electrónico: support@beexcc.com. El upgrade del plan se
    realizará de manera automática, de modo que una vez realizado este, el
    Cliente tendrá habilitadas las funcionalidades correspondientes al nuevo
    plan contratado. Beex realizará el cobro prorrateado del costo faltante
    respecto de la tarifa del nuevo plan adquirido, por los días que restaran
    para el término del período de vigencia del plan contratado inicialmente. El
    cobro por el total del precio del nuevo plan adquirido, se realizará en la
    siguiente fecha de renovación de la suscripción.
    <br /> <br />
    <strong id="Condiciones-de-acceso-y-utilización-de-la-Plataforma">
      {" "}
      4. Condiciones de acceso y utilización de la Plataforma{" "}
    </strong>
    <br /> <br />
    <strong> 4.1. Carácter del acceso y utilización de la Plataforma </strong>
    <br /> <br />
    4.1.1. Será considerado Cliente a todo usuario que se registra a través de
    la Plataforma y hace uso de los Servicios ofrecidos por Beex.
    <br /> <br />
    4.1.2. El Cliente podrá ser una persona natural o jurídica dedicada a
    prestar servicios en los rubros autorizados por Whatsapp Inc., de acuerdo a
    la política de comercio de WhatsApp Business API (
    <Link
      href="https://www.whatsapp.com/policies/commerce-policy/"
      target="_blank"
      rel="noopener noreferrer"
    >
      {" "}
      https://www.whatsapp.com/policies/commerce-policy/{" "}
    </Link>
    ).
    <br /> <br />
    4.1.3 Para hacer uso de la Plataforma y acceder a los Servicios ofrecidos
    por Beex, el Cliente debe declarar tener al menos dieciocho (18) años
    cumplidos; cuya nacionalidad podrá ser peruana o extranjera y contar con
    capacidad legal para celebrar contratos. Los menores de dieciocho (18) años
    no podrán hacer uso de la Plataforma y/o acceder a los Servicios ofrecidos
    por Beex; si el Cliente fuera menor de edad, le pedimos que no ingrese
    información personal a través de nuestra Plataforma. Asimismo, quien se
    registre en calidad de representante de una empresa, deberá tener capacidad
    y facultades para contratar a nombre de tal entidad y de obligar a la misma
    en los Términos y Condiciones de este contrato.
    <br /> <br />
    4.1.4 El Cliente será responsable de contar con los equipos necesarios, que
    cumplan con las características y requerimientos técnicos necesarios para
    acceder a la Plataforma y utilizar los Servicios, incluyendo la conexión a
    Internet. El Cliente será responsables de los costos generados por el uso de
    la Plataforma (equipos electrónicos, servicio de Internet, conexiones
    necesarias, etc.) no configurando ello responsabilidad de Beex.
    <br /> <br />
    4.1.5 Para acceder a los Servicios, el Cliente debe registrarse previamente
    y realizar el pago del plan contratado, según corresponda.
    <br /> <br />
    4.1.6 Proteger su privacidad es muy importante para Beex, por lo que el
    acceso a los Servicios se realiza a través de una contraseña que el Cliente
    ha asignado en el momento de su registro a través de la Plataforma o
    mediante una conexión a su cuenta de Google o Facebook. Toda acción
    realizada a través de la cuenta del Cliente, se presume realizada por este y
    no se admite prueba en contrario. Por lo tanto, el Cliente es el único que
    conoce esta información, por lo que no deberá revelar dicha información a
    terceros. Si considera que existe algún tipo de violación de seguridad o
    identifica que alguien accedió a su cuenta, debe notificarlo inmediatamente
    a Beex. Beex no se hace responsable de los daños y perjuicios que
    puedan suceder por actos de seguridad de responsabilidad del Cliente.
    <br /> <br />
    4.1.7 El Cliente declara y garantiza que la cuenta creada en la Plataforma
    es para su uso exclusivo y/o el de sus Agentes.
    <br /> <br />
    <strong> 4.2. Identificación del Cliente </strong>
    <br /> <br />
    Al momento de registrarse, el Cliente establecerá su identificación mediante
    la indicación de los datos solicitados en el formulario de registro, de
    acuerdo a lo señalado en nuestra Política de Privacidad, a través de la
    asignación de una contraseña segura, la cual será de exclusiva
    responsabilidad del Cliente y no deberá compartirla, o mediante una conexión
    a su cuenta de Google o Facebook.
    <br /> <br />
    En base a ello, el Cliente brinda su consentimiento para hacer uso de sus
    datos personales, de conformidad con lo dispuesto en la Política de
    Privacidad.
    <br /> <br />
    <strong> 4.3. Veracidad de la información </strong>
    <br /> <br />
    Toda la información que el Cliente facilite a Beex deberá ser veraz,
    certera, completa y actualizada. A estos efectos, el Cliente garantiza la
    autenticidad de todos aquellos datos que comunique como consecuencia del
    llenado y posterior envío a Beex de los formularios necesarios para el
    acceso y uso de la Plataforma. De igual forma, el Cliente será responsable
    de mantener permanentemente actualizada toda la información facilitada a
    Beex, de forma que responda, en cada momento, a su situación real y
    actual. En todo caso, el Cliente será el único responsable de las
    manifestaciones falsas o inexactas que realice y de los perjuicios que cause
    a Beex, a otros Clientes o a terceros por la información que facilite.
    Si el Cliente suministra información que es falsa, inexacta, desactualizada
    o incompleta, o si Beex tiene bases razonables para sospechar que dicha
    información es falsa, inexacta, desactualizada o incompleta, Beex
    tendrá el derecho de suspender o cerrar su cuenta y negarle el uso presente
    o futuro a la Plataforma, para la adquisición de los Servicios.
    <br /> <br />
    <strong> 4.4. Obligación de hacer un uso correcto de la Plataforma </strong>
    <br /> <br />
    El Cliente se compromete a utilizar la Plataforma, así como las
    funcionalidades habilitadas de acuerdo al plan de suscripción contratado, de
    conformidad con la Ley aplicable, los presentes Términos y Condiciones, así
    como de acuerdo con el orden público, la moral y las buenas costumbres. A
    tal efecto, el Cliente:
    <br /> <br />
    (a) Reconoce que el acceso a la Plataforma lo realiza de una manera libre y
    a su sola voluntad; asimismo, brinda su información y datos personales de
    manera voluntaria.
    <br />
    (b) No utilizará la información de la Plataforma o de terceros, a la que
    pudiera acceder en la misma, para ningún acto contrario a la legislación
    vigente en toda su extensión y/o afectar los derechos de terceros.
    <br />
    (c) Reconoce que el software, las marcas, nombres de dominio y otros signos
    distintivos son propiedad de Beex y que cualquier utilización de estos
    estará prohibida, salvo que cuente con la autorización correspondiente.
    <br />
    (d) Reconoce que los demás contenidos disponibles en la Plataforma, como
    logos, eslogans, diseño visual, entre otros, se encuentran protegidos por la
    legislación de derecho de autor y corresponde a sus respectivos titulares de
    derechos morales y/o patrimoniales autorizar su uso, por lo que no está
    permitido su uso fuera del libre acceso que pudiera tener en la Plataforma.
    <br />
    (e) Se abstendrá de modificar, reproducir, copiar, realizar ingeniería
    inversa, revertir la ingeniería, rediseñar, descompilar, adaptar, traducir,
    preparar trabajos derivados de la Plataforma, o usar la Plataforma para
    desarrollar cualquier software u otros materiales basados en el mismo.
    Asimismo, se obliga a utilizar la Plataforma únicamente en la forma
    permitida en este contrato, y queda expresamente prohibido de comercializar
    en ninguna forma la Plataforma, distribuir, otorgar licencias o
    sublicenciar, ceder ni transferir sus derechos de uso de la Plataforma.
    <br />
    (f) No brindará información falsa u ocultará intencionalmente alguna
    información a Beex, necesaria para brindar los Servicios tales como
    direcciones de correo electrónico, información de cuenta o información que
    se pueda requerir al momento de identificarse o para la ejecución de los
    Servicios.
    <br /> <br />
    Las obligaciones señaladas son exigibles y aplicables a los Agentes del
    Cliente. El incumplimiento por parte del Cliente y/o de sus Agentes de
    cualquiera de las condiciones precedentes, u otras contenidas en los
    presentes Términos y Condiciones, podrá implicar el impedimento inmediato de
    acceso a la Plataforma y, de corresponder, la denuncia respectiva ante las
    autoridades pertinentes.
    <br /> <br />
    <strong id="Tu-seguridad"> 5. Tu seguridad </strong>
    Proteger su privacidad es muy importante para Beex, el acceso a la
    Plataforma y a los Servicios ofrecidos por Beex, se realiza a través de
    una contraseña que el Cliente ha asignado en el momento del registro o
    mediante una conexión a su cuenta de Google o Facebook. Por lo tanto, el
    Cliente es el único que conoce esta información. El Cliente no deberá
    revelar dicha información a terceros, habiendo sido creada la cuenta para
    uso exclusivo del Cliente.
    <br /> <br />
    Beex no revela los datos de su cuenta, direcciones postales, correo
    electrónico, operaciones, datos personales a terceros; excepto ante un
    mandato emitido por una autoridad competente.
    <br /> <br />
    Beex cuenta con una Política de Privacidad que forma parte de los
    presentes Términos y Condiciones, por lo que te recomendamos leerlas previo
    al acceso a la Plataforma y/o uso de los Servicios. Esta Política es
    aplicada durante todo el proceso y el mantenimiento de la información del
    Cliente.
    <br /> <br />
    <strong id="Condiciones-de-uso-de-los-Servicios">
      {" "}
      6. Condiciones de uso de los Servicios{" "}
    </strong>
    <br /> <br />
    6.1. Para la atención al cliente a través de sus Agentes, el Cliente podrá
    habilitar determinado número de “usuarios” -de acuerdo a las condiciones del
    plan contratado-, que son las credenciales mediante los cuales se les
    permitirá a los Agentes el acceso a la Plataforma.
    <br /> <br />
    6.2. El Cliente mantendrá la total responsabilidad y mantendrá indemne a
    Beex por la actividad de sus Agentes; siendo que estos últimos deberán
    hacer uso de la Plataforma y los Servicios ofrecidos en concordancia con los
    presentes Términos y Condiciones.
    <br /> <br />
    6.3. El Cliente podrá supervisar el estado de las acciones de sus Agentes
    asignados, de modo que le permita conocer en que apartados de la Plataforma
    se encuentran sus agentes, facilitando la supervisión de las operaciones.
    Asimismo, a través de los planes Business y Entreprise, el Cliente podrá
    monitorear individualmente las conversaciones de sus Agentes.
    <br /> <br />
    6.4. El Cliente podrá crear colas de gestión, a través de la configuración
    dinámica disponible en la Plataforma, de modo que le permita asignar tickets
    a determinado grupo de agentes.
    <br /> <br />
    6.5. El Cliente podrá acceder a dashboards descargables, los mismos que le
    permitirán conocer toda su data, como tickets generados, tickets nuevos,
    estado de tickets, tiempos promedio de respuestas, tiempos de trabajo de
    agente, entre otros. Una vez finalizado el plan contratado, el Cliente
    tendrá 30 días para descargar la información disponible en los dashboards.
    <br /> <br />
    6.6. El Cliente podrá crear un número determinado de chatbots -de acuerdo al
    plan contratado-, que le permita atender 24x7 a sus clientes, a través de la
    automatización, todos sus tickets de atención, mediante árboles de decisión
    ilimitados por rangos de horarios.
    <br /> <br />
    6.7. Como parte de sus Servicios, Beex brinda el servicio de asistencia
    y/o soporte técnico ante cualquier eventualidad o problema generado, que
    pueda afectar la seguridad y/o funcionamiento de la Plataforma. Para ello,
    el Cliente deberá contactarse a través del envío de un correo electrónico a:
    support@beexcc.com, y se le dará respuesta en un plazo no mayor de 72
    horas.
    <br /> <br />
    <strong id="Integración-con-proveedores">
      {" "}
      7. Integración con proveedores{" "}
    </strong>
    <br /> <br />
    7.1 Para el uso integral de la Plataforma y de los Servicios ofrecidos por
    Beex, es necesario que el Cliente adquiera de algunos de los
    prestadores oficiales la API de WhatsApp Business (los “Proveedores”), para
    lo cual el Cliente deberá sujetarse a los términos y políticas de dichos
    Proveedores.
    <br /> <br />
    Beex actúa como una plataforma intermediaria, sin ninguna
    representación en la relación existente entre los Proveedores y el Cliente;
    por tanto, Beex no será responsable ni estará obligado a realizar
    ningún tipo gestión por parte del Cliente ante los mencionados Proveedores.
    <br /> <br />
    El Cliente será el único y exclusivo responsable de negociar los términos y
    condiciones para el acceso a la API de WhatsApp Business ofrecida por los
    Proveedores. Beex no asumirá ningún tipo de responsabilidad por las
    fallas o inconvenientes en los servicios ofrecidos por los Proveedores y, en
    consecuencia, por la interrupción en el acceso a los Servicios ofrecidos por
    Beex, dado que no tiene ningún tipo de injerencia sobre ellos.
    <br /> <br />
    7.2 Por su parte, Beex es partner de los siguientes integradores
    oficiales: Gupshup e (*); de ese modo Beex subcontrata la licencia
    otorgada por WhatsApp Inc para ofrecer el servicio de integración con la API
    de WhatsApp Business, el mismo que podrá ser adquirido por el Cliente en el
    Market de la Plataforma (el “Servicio de Integración”).
    <br /> <br />
    Para tener acceso al servicio de integración, el Cliente podrá elegir entre:
    (i) realizar el pago de un fee mensual; o (ii) realizar el pago por cada
    consumo de mensaje enviado o recibido y por cada plantilla utilizada. Las
    condiciones y precios para el acceso al servicio de integración, serán
    comunicados en la Plataforma y/o a través de un asesor de ventas. El costo
    para el acceso a la API de WhatsApp Business, podrá variar de acuerdo a
    cambios realizados por WhatsApp Inc.
    <br /> <br />
    <strong id="Límites-de-responsabilidad-de-Beex">
      {" "}
      8. Límites de responsabilidad de Beex{" "}
    </strong>
    <br /> <br />
    El Cliente expresamente comprende y conviene que:
    <br /> <br />
    (a) Los Servicios son ofrecidos tal cual son, por lo que Beex no otorga
    garantías de ningún tipo, incluyendo -sin limitación- a las garantías
    implícitas de comercialidad y las de aptitud para un propósito en
    particular.
    <br />
    (b) Beex no garantiza que los Servicios ofrecidos a través de la
    Plataforma serán ininterrumpidos o libres de error.
    <br />
    (c) Será el único y exclusivo responsable sobre el uso de su cuenta y
    contraseña, y sobre la creación de los usuarios o credenciales para sus
    Agentes.
    <br />
    (d) Será el único y exclusivo responsable de que sus Agentes cumplan estos
    Términos y Condiciones. En ese sentido, el Cliente mantendrá la total
    responsabilidad y mantendrá indemne a Beex por la actividad de sus
    Agentes.
    <br />
    (e) Utilizará la Plataforma y los Servicios ofrecidos por Beex para
    comunicarse con sus clientes y/o terceros a través de WhatsApp y Facebook
    Messenger; sin embargos, dichos clientes y/o terceros no forman parte de
    este contrato.
    <br />
    (f) En caso el Cliente adquiere el Servicio de Integración ofrecido por
    Secutirec en el Market de la Plataforma, el Cliente conoce y acepta que el
    Servicio de Integración podrá ser suspendido o interrumpido debido a fallas
    técnicas u operacionales, sin que ello genere ningún tipo de responsabilidad
    a Beex, hasta por un periodo mensual máximo de 72 horas.
    <br />
    (g) El acceso del Cliente y/o de sus Agentes a la Plataforma puede ser
    ocasionalmente restringido o suspendido con el objeto de efectuar
    reparaciones, mantenimiento o introducir nuevas funcionalidades, previa
    comunicación al Cliente, sin que ello genere ningún tipo de responsabilidad
    frente al Cliente y/o terceros. Lo dispuesto en el presente literal abarca
    al supuesto señalado en el literal (f) de la presente cláusula.
    <br />
    (h) El Cliente acepta que Beex se reserva el derecho de dar de baja
    cuentas que han estado inactivas por un período prolongado de tiempo y el
    derecho de modificar estas prácticas generales en cualquier momento, a su
    solo arbitrio, sin necesidad de previa notificación.
    <br />
    (i) Beex quedará exenta de toda responsabilidad, cuando el cumplimiento
    de sus obligaciones se vea afectado por eventos de caso fortuitos o fuerza
    mayor, por causas ajenas a su control, incluyendo, pero no limitando a:
    fallas operacionales de WhatsApp Business API -incluyendo las fallas
    operacionales por causas imputables a los Proveedores-, cambio en las
    políticas de WhatsApp Business API, entre otros.
    <br />
    (j) Beex no se hace responsable por alguna pérdida de información, o
    por el borrado o falla en el almacenamiento de mensajes u otras
    comunicaciones, debido a fallas técnicas por el uso indebido o no autorizado
    de la Plataforma, así como por fallas eléctricas o técnicas ajenas a
    Beex.
    <br />
    (k) El Cliente es el único y exclusivo responsable del uso que se le dé a la
    Plataforma, así como cualquier acción que tenga lugar mediante dicho uso y,
    en tal sentido, no cabe imputación de responsabilidad alguna frente a
    Beex, ya sea por uso indebido de la Plataforma, o por incumplimiento de
    la normativa que corresponda aplicar de acuerdo a la materia y a la
    jurisdicción regulada.
    <br />
    (l) Beex no se responsabiliza por ninguna consecuencia, daño o
    perjuicio relativo al uso de la Plataforma o Servicios ofrecidos por
    Beex que fueran consecuencia de la relación entre el Cliente y/o sus
    Agentes y sus clientes.
    <br />
    (m) El Cliente mantendrá indemne a Beex, sus filiales, empresas
    controladas y/o controlantes, directivos, administradores, representantes y
    empleados, respecto de cualquier gasto, reclamo, sanción, costes -incluida
    la defensa a través de abogado–, demanda de terceros por las actividades que
    el Cliente y/o sus Agentes puedan realizar en la Plataforma, y/o por
    cualquier incumplimiento a los presentes Términos y Condiciones y demás
    Políticas que se entienden incorporadas al presente o por la violación de
    cualquier ley o derechos de terceros.
    <br /> <br />
    Lo dispuesto en la presente cláusula se aplica de manera general, a menos de
    que exista una disposición legal en contra.
    <br /> <br />
    <strong id="Independencia-del-Cliente-y-Beex">
      {" "}
      9. Independencia del Cliente y Beex{" "}
    </strong>
    <br /> <br />
    El Cliente y Beex, a todos los efectos de este contrato, son entidades
    independientes y autónomas, de forma que el presente contrato no implica la
    creación de ninguna modalidad de vínculo entre ambas partes, sea de
    representación, mandato, asociación, formación de grupo económico u otros.
    Cada parte será la única y exclusiva responsable por el pago de sus
    obligaciones tributarias, de seguridad social y laborales. Cada parte será
    la única y exclusiva responsable de sus respectivos empleados,
    colaboradores, locadores y terceros, por ellas contratados. Cada parte se
    obliga a mantener indemne a la otra ante cualquier acción en la que se
    demanda a la otra alegando la existencia de grupo económico, vinculación
    comercial y/o cualquiera de las figuras señaladas en esta cláusula;
    asumiendo la defensa, los gastos y costas irrogados por la otra.
    <br /> <br />
    <strong id="Propiedad-Intelectual"> 10. Propiedad Intelectual </strong>
    <br /> <br />
    Todas las marcas, nombres comerciales, nombres de dominio o signos
    distintivos de cualquier clase que aparecen en la Plataforma, son de
    propiedad de Beex o de sus respectivos titulares; sin que pueda
    entenderse que el uso, acceso o utilización de la Plataforma, atribuya al
    Cliente derecho alguno sobre las citadas marcas, nombres comerciales,
    nombres de dominio y/o signos distintivos.
    <br /> <br />
    El software asociado a la Plataforma es de propiedad exclusiva de Beex,
    y/o de ser el caso, de sus proveedores o de sus servidores externos; por
    tanto, están protegidos por las leyes peruanas y, por las leyes y los
    tratados internacionales en materia de derechos de autor, marcas, patentes,
    modelos y diseños industriales aplicables en el Perú.
    <br /> <br />
    El Cliente no adquiere ningún derecho de propiedad intelectual por el uso de
    la Plataforma y de los Servicios ofrecidos por Beex, no siendo
    considerado una autorización ni licencia para utilizar la Plataforma y los
    Servicios con fines distintos a los mencionados en el presente contrato. En
    base a ello, se prohíbe al Cliente utilizar, codificar, copiar, distribuir,
    transmitir o comercializar los derechos de Beex y/o de terceros
    involucrados en la prestación de los Servicios y la Plataforma.
    <br /> <br />
    El incumplimiento de lo aquí mencionado sujetará al infractor a todos los
    reclamos civiles que correspondan, así como a las denuncias o las
    reclamaciones ante las autoridades competentes, dentro de la cual se
    proporcionará la información necesaria para la investigación y persecución
    del ilícito del que se trate.
    <br /> <br />
    <strong id="Duración-y-terminación-del-contrato">
      {" "}
      11. Duración y terminación del contrato{" "}
    </strong>
    <br /> <br />
    El presente contrato tiene duración indefinida. No obstante, Beex podrá
    dar por terminada o suspender los Servicios o, la totalidad o parte de los
    mismos en cualquier momento. Beex comunicará previamente al Cliente
    sobre la terminación o suspensión de los Servicios o parte de los mismos.
    <br /> <br />
    Asimismo, el Cliente acepta que Beex puede, bajo ciertas circunstancias
    y sin necesidad de notificación previa, cancelar y terminar inmediatamente
    el presente contrato. Entre las causales de terminación se incluyen, sin
    limitarse: (a) incumplimientos o violaciones a estos Términos y Condiciones
    o a otros acuerdos o lineamientos incorporados; (b) requerimientos de
    autoridades legales o gubernamentales. El Cliente aceptan que todas las
    causales de terminación con causa podrán ser invocadas por Beex a su
    sola discreción. Beex no será responsable frente al Cliente, ni frente
    a ningún tercero, por cualquier terminación de los Servicios.
    <br /> <br />
    En caso de incumplimiento de cualquiera de las disposiciones contenidas en
    los presentes Términos y Condiciones, Beex podrá, de ser el caso,
    presentar las denuncias ante las autoridades competentes. Se considerará
    infracción a los presentes Términos y Condiciones, la comisión o
    participación en actos que constituyan conductas abusivas, inapropiadas o
    ilícitas, actividades fraudulentas o ilegales y en general el comercio de
    cualquier objeto ilícito o producto de un delito.
    <br /> <br />
    La terminación de la cuenta del Cliente en Beex incluye: (a) la
    cancelación del acceso a los Servicios ofrecidos; (b) el borrado de toda la
    información relacionada y de todo el contenido asociado al Cliente (o de
    cualquier parte de la misma), en caso; y (c) prohibir el acceso posterior de
    los Servicios.
    <br /> <br />
    Lo dispuesto en la presente cláusula se aplica de manera general, a menos de
    que exista una disposición legal en contra.
    <br /> <br />
    <strong id="Cuentas-duplicadas"> 12. Cuentas duplicadas </strong>
    <br /> <br />
    Beex no permite la creación de cuentas de usuario duplicadas para un
    mismo Cliente, debido a los requisitos de seguridad y de identificación del
    Cliente. En caso de que se detecten cuentas duplicadas, Beex cerrará o
    fusionará estas cuentas de usuario duplicadas sin notificación previa.
    Beex queda exenta de todo tipo de responsabilidad por dicha situación.
    <br /> <br />
    <strong id="Términos-Generales"> 13. Términos Generales </strong>
    <br /> <br />
    <strong> 13.1. Quejas, Reclamos y Solicitudes </strong>
    <br /> <br />
    Estamos comprometidos a proporcionarle los más altos estándares de servicio.
    Beex acepta todo tipo de duda, queja o comentario con respecto de los
    Servicios o los presentes Términos y Condiciones; el Cliente puede
    escribirnos un correo electrónico a la siguiente dirección:
    support@beexcc.com.
    <br /> <br />
    Conforme a lo establecido en el Código de Protección y Defensa del
    Consumidor, Ley Nº 29571, Beex pone a disposición del Cliente en la
    Plataforma un libro de reclamaciones virtual en donde el Cliente podrá
    formular una queja o reclamo.
    <br /> <br />
    <strong> 13.2. Disponibilidad de los Servicios </strong>
    <br /> <br />
    Los Servicios estarán disponibles salvo que existan circunstancias de fuerza
    mayor, caso fortuito o hechos de terceros que impidan o restrinjan el acceso
    o uso de los Servicios, esto puede incluir y no se limita a cambios
    regulatorios. Beex no será responsable por ningún daño o pérdida de
    cualquier naturaleza que pueda ser causado debido a la falta de
    disponibilidad o continuidad de operación de los Servicios. A los fines de
    esta cláusula, quedan comprendidos en la definición de fuerza mayor,
    cualquier cambio en las políticas comerciales de WhatsApp Inc. o de los
    Proveedores oficiales de WhatsApp Business API que impliquen que Beex
    se vea impedido de prestar sus Servicios.
    <br /> <br />
    Asimismo, Beex en cualquier momento y periódicamente, previo aviso al
    Cliente, se reserva el derecho de modificar, o discontinuar los Servicios
    ofrecidos en la Plataforma o cualquier parte del mismo temporalmente, por
    razones de seguridad o mantenimiento; incluyendo el supuesto regulado en el
    literal (f) de la cláusula 8. En los supuestos señalados, Beex no será
    responsable hacia el Cliente o terceras partes por ninguna suspensión o
    interrupción del funcionamiento de la Plataforma.
    <br /> <br />
    En caso alguno de los planes que ofrece Beex dejen de estar disponibles
    para el público en general, se respetarán las condiciones de los Clientes
    que ya hubieran adquirido dichos planes en su momento hasta el término del
    plazo de los mismos.
    <br /> <br />
    Lo dispuesto en la presente cláusula se aplica de manera general, a menos de
    que exista una disposición legal en contra.
    <br /> <br />
    <strong> 13.3. Actualización de los Términos y Condiciones </strong>
    <br /> <br />
    Beex podrá realizar actualizaciones y cambios sobre los Términos y
    Condiciones, de acuerdo a cambios y/o exigencias regulatorias, o por
    adecuaciones propias de los Servicios establecidas a sola discreción y
    criterio de Beex. Toda actualización entrará en vigencia desde su
    publicación a través de la Plataforma.
    <br /> <br />
    Por su parte, Beex realizará los esfuerzos necesarios para enviar una
    notificación de dicha actualización al Cliente que se encuentre operando a
    través del envío de un correo electrónico a su correo registrado.
    <br /> <br />
    En caso de no estar de acuerdo con las actualizaciones o cambios que se
    pudiesen dar, el Cliente podrá cancelar su suscripción y/o presentar sus
    reclamos, dudas y/o consultas al siguiente correo electrónico
    support@beexcc.com. Asimismo, en caso cualquiera de tales modificaciones o
    actualizaciones no sean aceptados por el Cliente o no esté de acuerdo con
    los mismos, deberá dejar de usar inmediatamente la Plataforma. El uso de la
    Plataforma constituye aceptación total e irrevocable del Cliente de los
    cambios y modificaciones realizadas.
    <br /> <br />
    <strong id="Legislación-y-Jurisdicción-Aplicable">
      {" "}
      14. Legislación y Jurisdicción Aplicable{" "}
    </strong>
    <br /> <br />
    En caso que cualquier disposición de los presentes Términos y Condiciones
    sea ilegal, nula o por cualquier razón o no se pueda hacer cumplir, la
    disposición se considerará separable del presente texto y no afectará la
    validez y ejecutabilidad de las disposiciones restantes.
    <br /> <br />
    Los presentes Términos y Condiciones son declaración exclusiva y total de
    acuerdo entre el Cliente y Beex con respecto a la Plataforma y a los
    Servicios ofrecidos, y el presente texto reemplaza cualquier acuerdo
    contemporáneo o anterior tanto oral como escrito y cualquier comunicación
    con respecto al mismo entre el Cliente y Beex.
    <br /> <br />
    La renuncia o tolerancia por parte de Beex respecto del ejercicio de
    algún derecho o disposición de los Términos y Condiciones detallados, no
    constituirá renuncia a dicho derecho o disposición.
    <br /> <br />
    Estos Términos y Condiciones se rigen por las leyes de la República del
    Perú. La jurisdicción para todas las disputas que se deriven de la relación
    entre el Cliente y Beex serán los Juzgados y Tribunales del Distrito
    Judicial de Lima, Perú, renunciando expresamente a cualquier otra
    jurisdicción que pudiera corresponderles en razón de sus domicilios presente
    o futuros, sometiéndose a la competencia y jurisdicción de los Jueces y
    Tribunales de Lima, Perú.
    <br /> <br />
    Los títulos de los artículos de estos Términos y Condiciones tienen fines de
    referencia y se destinan a facilitar la lectura, careciendo de efectos para
    su interpretación.
  </span>
)

const data = {
  description: {
    title: "Términos y Condiciones ",
    textbody: text,
    intro: "Introducción",
    class: "container-menu-cookies",

    menu: [
      {
        text: "Empresa",
        href: "#Empresa",
      },
      {
        text: "Objeto del Contrato",
        href: "#Objeto-del-Contrato",
      },
      {
        text: "Servicio",
        href: "#Servicio",
      },

      {
        text: "Condiciones de acceso y utilización de la Plataforma",
        href: "#Condiciones-de-acceso-y-utilización-de-la-Plataforma",
      },

      {
        text: "Tu seguridad",
        href: "#Tu-seguridad",
      },
      {
        text: "Condiciones de uso de los Servicios",
        href: "#Condiciones-de-uso-de-los-Servicios",
      },
      {
        text: "Integración con proveedores",
        href: "#Integración-con-proveedores",
      },
      {
        text: "Límites de responsabilidad de Beex",
        href: "#Límites-de-responsabilidad-de-Beex",
      },
      {
        text: "Independencia del Cliente y Beex",
        href: "#Independencia-del-Cliente-y-Beex",
      },

      {
        text: "Propiedad Intelectual",
        href: "#Propiedad-Intelectual",
      },
      {
        text: "Duración y terminación del contrato",
        href: "#Duración-y-terminación-del-contrato",
      },
      {
        text: "Cuentas duplicadas",
        href: "#Cuentas-duplicadas",
      },
      {
        text: "Términos Generales",
        href: "#Términos-Generales",
      },
      {
        text: "Legislación y Jurisdicción Aplicable",
        href: "#Legislación-y-Jurisdicción-Aplicable",
      },
    ],
  },
}

export default data
